.filter-container {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 1em;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  letter-spacing: 0.16px;
  line-height: 1.38;
  padding: 1em;
  margin-bottom: 2em;
}

.filter-container button {
  height: 2.5em;
  font-family: Roboto, sans-serif;
  justify-content: center;
  border-radius: 100px;
  flex: 1;
  padding: 0.5em;
  text-decoration: none;
  color: inherit;
  font-size: 0.8em;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: white;
}

.filter-container button:hover {
  background-color: #121926;
  color: white;
}

.filter-container button.active {
  background-color: #121926;
  color: white;
}

.filter-container button {
  cursor: pointer;
}
