/* VideoPlayer.css */
.video-container {
  position: relative;
  width: 850px;
  height: 400px;
  max-width: 100%;
  margin: auto;
}

video {
  width: 100%;
  height: 385px;
}

.video-container.tv {
  height: 400px;
}

.video-container.radio {
  height: 50px;
  margin-top: 20px;
}

video.tv {
  height: 380px;
}

video.radio {
  height: 150px;
  display: none;
}

video::-webkit-media-controls {
  display: none !important;
}

.video-container:hover video::-webkit-media-controls {
  display: flex !important;
}

.video-container:hover video {
  cursor: pointer;
}

.video-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background: white;
  color: #121926;
  font-size: 12px;
}

.video-controls input[type="range"] {
  flex-grow: 2;
  margin: 0 10px;
  -webkit-appearance: none;
  background: transparent;
}

.video-controls input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: rgb(212, 208, 208);
  border-radius: 0px;
  padding: 5px;
}

.video-controls input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  background: #121926;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -7px; /* Ajusta a posição da bolinha */
}

.volume-control {
  display: flex;
  align-items: center;
  color: #121926;
  margin-right: 10px;
}

.volume-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  color: #121926;
}

.play-pause-button, .fullscreen-button {
  cursor: pointer;
  background: none;
  border: none;
  color: #121926;
  font-size: 20px;
  margin-right: 10px;
  margin-top: 2px;
}

.tv-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin: -20px 0 0 -20px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.play-pause-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.playback-rate-toggle {
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
  cursor: pointer;
}

.playback-rate-button {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
}

.playback-rate-button:hover {
  background-color: #121926;
  color: white;
}

.playback-rate-button svg {
  margin-right: 5px;
  width: 12px;
  height: 12px;
}



@media (max-width: 1441px) {
  .video-controls {
    font-size: 10px;
  }
  
  .fullscreen-button {
    margin-right: -10px;
  }
}

@media (max-width: 1025px) {
  .total-time {
    display: none;
  }
}

@media (max-width: 431px) {
  .video-controls {
    margin-left: -20px;
  }

  .video-controls input[type="range"] {
    width: 30px;
  }
}

@media (max-width: 391px) {
  .video-controls {
    margin-left: -10px;
  }

  .playback-rate-button {
    font-size: 8px;
  }

  .video-controls input[type="range"] {
    width: 20px;
  }
}

@media (max-width: 321px) {
  .video-controls {
    margin-left: -25px;
    font-size: 9px;
  }

  .play-pause-button, .fullscreen-button {
    font-size: 15px;
  }
}
