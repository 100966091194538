.table-chart-container {
    width: 100%;
    overflow-x: auto;
    margin-top: 100px;
}

.table-chart {
    width: 100%;
    border-collapse: collapse;
}

.table-chart th, .table-chart td {
    border: 1px solid #ddd;
    padding: 8px;
}

.table-chart th {
    background-color: #f2f2f2;
    font-weight: bold;
    text-align: left;
}

.table-chart td {
    text-align: left;
}