.clipping-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  width: 100%;
}

.clipping-list .card {
  flex: 1 1 100%;
  box-sizing: border-box;
  min-width: 0;
  max-width: 100%;
}

.clipping-list a {
  text-decoration: none;
}

.card {
  min-height: 100px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 15px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.card:hover {
  background-color: rgb(248, 247, 247);
}

.card.evaluated {
  border-color: #4CAF50;
}

.card.not-evaluated {
  border-color: #F44336;
}

.card-header {
  display: flex;
  gap: 14px;
}

.thumbnail-wrapper {
  flex: 0 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail {
  width: 70%;
  height: 70%;
  object-fit: cover;
  border-radius: 50%;
}

.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.card-title {
  color: var(--Foundation-Blue-blue-800, #202939);
  font: 600 16px/1.4 IBM Plex Sans, -apple-system, Roboto, Helvetica, sans-serif;
  overflow: hidden;
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-wrap: break-word;
  white-space: normal;
  margin-top: 8px;
  width: 100%;
}

.card-details {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
  color: #000;
  line-height: 160%;
}

.detail-separator {
  margin: 0 4px;
}

.detail-value {
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

.detail-value.evaluated {
  color: #4CAF50;
}

.detail-value.not-evaluated {
  color: #F44336;
}

.modal-overlay-card {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Fundo escuro semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-card {
  width: 95%; /* Ocupa 90% da largura da tela */
  height: 95%; /* Ocupa 90% da altura da tela */
  background-color: #fff;
  padding: 5px;
  border-radius: 8px;
  overflow-y: auto; /* Permite rolagem se o conteúdo exceder a altura */
  position: relative;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 22px;
  cursor: pointer;
  border: 1px solid #121926;
}

.modal-close:hover {
  background-color: #121926;
  color: #fff;
}

.modal-navigation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; /* Faz as setas ocuparem toda a largura do modal */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-button {
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: #333;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  margin-left: 20px;
  margin-right: 20px;
}

.nav-button.left-button {
  position: absolute;
  left: 10px;
}

.nav-button.right-button {
  position: absolute;
  right: 10px;
}

.nav-button:hover {
  background-color: #121926;
  color: #fff;
}

.nav-button:disabled {
  color: #ccc;
  cursor: not-allowed;
}
