.editar-clipping {
    position: relative;
  }
  
  .navbar-editar-clipping {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  }
  
  .menu-editar-clipping {
  position: fixed;
  top: 0;
  left: 0;
  /* right: 0; */
  z-index: 3;
  }
  
  .editar-clipping.menu-expanded .editar-clipping-container {
  margin-top: 90px;
  margin-right: 45px;
  margin-left: 220px;
  margin-bottom: 40px;
  }

  .editar-clipping.menu-collapsed .editar-clipping-container {
    margin-top: 90px;
    margin-right: 45px;
    margin-left: 115px;
    margin-bottom: 40px;
    }

  .editar-clipping-header {
    display: flex;
    width: 104%;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
  }

  .editar-clipping-title {
    align-self: start;
    display: flex;
    gap: 8px;
    font-size: 24px;
    color: var(--text-primary, rgba(0, 0, 0, 0.87));
    font-weight: 600;
    line-height: 135%;
  }

  .editar-clipping-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 30px;
    margin: auto 0;
  }

  .editar-clipping-title-text {
    font-family: IBM Plex Sans, sans-serif;
  }



.menu-editar-clipping,
.navbar-editar-clipping {
    margin-bottom: 20px;
}

.editar-clipping-container {
    padding: 20px;
}

.editar-clipping-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.editar-clipping-title {
    display: flex;
    align-items: center;
}

.editar-clipping-home-link {
    margin-right: 10px;
}

.editar-clipping-icon {
    width: 50px;
    height: 50px;
}

.editar-clipping-title-text {
    font-size: 24px;
    font-weight: bold;
}

.dados-editar-clipping {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Permite que a linha quebre quando necessário */
  margin-top: 40px;
  gap: 20px; /* Adiciona espaço entre os itens, opcional */
}

.dados-clipping-item {
    margin-right: 2px; /* Ajuste o valor conforme necessário */
}

.dados-clipping-label {
    font-weight: bold;
}

.hr-horizontal-editar-clipping {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.dados-resumo-clipping-label {
    font-weight: bold;
}





.alterar-clipping {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 40px;
}

.dados-clipping {
    flex: 1; /* Para ocupar o espaço disponível à esquerda */
    max-width: 30%; /* Ajuste a largura máxima para 20% */
    border: 1px solid rgba(0, 0, 0, 0.20);
    border-radius: 5px;
    margin-top: 52px;
    height: 340px;
}

/* Ajustes para a classe texto-clipping */
.texto-clipping {
  flex: 4; /* Ajuste para ocupar mais espaço */
  max-width: 70%; /* Ajuste a largura máxima para 70% */
  font-size: 7px;
}

/* Ajustes para a classe text-clipping */
.text-clipping {
  border: 1px solid rgba(0, 0, 0, 0.20);
  border-radius: 5px;
  font-size: 20px;
  height: 340px;
  max-height: 340px; /* Define a altura máxima */
  max-width: 100%; /* Define a largura máxima */
  overflow-y: auto; /* Adiciona scroll vertical */
  overflow-x: auto; /* Adiciona scroll horizontal */
  padding: 10px; /* Adiciona padding para evitar que o texto encoste na borda */
  box-sizing: border-box; /* Inclui padding e border na largura e altura total do elemento */
}


.filter-search-editar-clipping {
    margin-top: 20px;
    margin-left: 20px;
    width: 95%;
    align-items: center;
    justify-content: center;
}

.button-limpar-editar-clipping {
    display: flex;
    align-items: center;
    justify-content: baseline;
}

.button-limpar-editar-clipping button {
    margin-left: 25px;
    display: flex;
    text-transform: uppercase;
    font-weight: bold;
    width: 135px;
    height: 50px;
    padding: 8px 22px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: var(--borderRadius, 4px);
    border: 1px solid var(--components-input-outlined-enabledBorder, rgba(0, 0, 0, 0.23));
    background-color: transparent;
}



.Data-editar-clipping {
    margin-top: 50px;
    margin-left: 25px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.date-picker-search-editar-clipping {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    font-weight: 400;
    letter-spacing: 0.15px;
    justify-content: center;
    padding: 0 8px;
    height: 50px;
    width: 170px;
  }

  .date-picker-header-search-editar-clipping {
    align-items: start;
    display: flex;
    padding-bottom: 16px;
  }

  .date-picker-content-search-editar-clipping {
    display: flex;
    flex-direction: column;
  }

  .date-picker-label-search-editar-clipping {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.2);
    justify-content: center;
    padding: 0px 2px 5px 4px;
    font: 10px/100% Roboto, sans-serif;
    width: 80px;
  }

  .selected-date-search-editar-clipping {
    color: rgba(0, 0, 0, 0.87);
    margin-top: 5px;
    margin-left: 4px;
    font: 12.5px/150% Roboto, sans-serif;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .calendar-icon-search-editar-clipping {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 20px;
    margin-top: 7px;
    margin-left: 35px;
    opacity: 0.4;
  }

  .selected-date-input-editar-clipping {
    border: none;
    margin-top: 10px;
    width: 100%;
    outline: none;
    opacity: 0.4;
  }


  .button-salvar-editar-clipping {
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 25px;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .button-salvar-editar-clipping button {
    display: flex;
    height: 56px;
    padding: 8px 22px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: var(--borderRadius, 4px);
    background: var(--Foundation-Blue-blue-900, #121926);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);
    color: white;
    text-transform: uppercase;
    cursor: pointer;
  }

  .resumo-clipping {
    width: 100%;
}

.dados-resumo-clipping {
    width: 100%;
    white-space: normal; /* Permite que o texto quebre para a próxima linha */
    overflow: visible; /* Garante que o texto não seja cortado */
    word-wrap: break-word; /* Garante que palavras longas sejam quebradas */
}

.resume-label{
  font-size: 14px;
}

.dados-resumo-clipping-label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 17px;
}


  .dropdown-categorias {
    margin-top: 15px;
  }

  .success-message, .error-message {
    position: fixed;
    top: 100px;
    right: 10px;
    padding: 10px;
    border-radius: 5px;
    z-index: 1000;
    width: auto;
    max-width: 500px;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: left;
}

.success-message {
    background-color: #EDF7ED;
    color: #1e4620;
}

.error-message {
    background-color: rgb(179, 34, 34);
    color: white;
}

.success-message::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url('https://cdn.builder.io/api/v1/image/assets/TEMP/58c853922bacfc9eff3ea835421841f405fbe7281d7b893cf99fffe69ed464c9?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d&');
    background-size: cover;
}



  