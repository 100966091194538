.actions-menu-modal {
    position: absolute;
    background: white;
    border: 1px solid #f9f9f9;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    margin-top: 2px;
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  
  .actions-menu-modal button {
    display: block;
    width: 95%;
    margin: 5px 0;
    background: #eeeeee;
    border: none;
    text-align: left;
    cursor: pointer;
    font-size: 11.5px;
    font-family: 'Arimo', sans-serif;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
    height: 35px;
}
  
  .actions-menu-modal button:hover {
    background: #121926;
    color: white;
  }
  