.general {
  height: 40px;
  border-color: rgba(245, 245, 245, 1);
  border-style: solid;
  border-bottom-width: 2px;
  background-color: #fff;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 10px 42px 10px 0;
}

.buttonMenu {
  justify-content: center;
  align-items: center;
  border-color: rgba(238, 242, 246, 1);
  border-style: solid;
  border-width: 2px;
  background-color: var(--Foundation-Blue-blue-900, #121926);
  display: flex;
  width: 20px;
  height: 25px;
  margin: auto 0;
  padding: 0 3px;
}
.img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 26px;
}

.img-2 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 32px;
  align-self: start;
  transition: 0.5s ease;
  margin-top: 4px;
}

.img-2:hover {
  transform: scale(1.1);
}

.text {
  display: flex;
  gap: 20px;
}

.div-4 {
  display: flex;
  gap: 20px;
  font-size: 14px;
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
  font-weight: 500;
  line-height: 22px;
  justify-content: space-between;
  flex-grow: 1;
  flex-basis: auto;
}

.textAbout {
  width: 300px;
  font-family: IBM Plex Sans, sans-serif;
  margin: auto 0;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  margin-left: 200px;
}
.Notification {
  display: flex;
  gap: 16px;
  font-size: 16px;
  color: var(--Greyscale-900, #1a202c);
  font-weight: 600;
  line-height: 150%;
  flex-grow: 1;
  flex-basis: auto;
}

.div-8 {
  width: 185px;
  height: 20px;
  border-radius: 80px; 
  border-color: rgba(0, 0, 0, 0.23);
  border-style: solid;
  border-width: 1px;
  background-color: #fafafa;
  justify-content: space-between;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  padding: 8px;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}


.div-8 p {
  margin: 0; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.companyLogo {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.img-3 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 28px;
  border-radius: 50%;
}

.NamePerson {
  font-family: IBM Plex Sans, sans-serif;
  margin: auto 0;
  font-size: 13px;
  font-weight: 500;
}

.img-4 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 16px;
  margin: auto 0;
}

.notificationImage {
  width: 34px;
}

/* NavBar.css */
.NamePersonButton {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  color: inherit;
  font-size: 14px;
  margin-left: 10px ;
  margin-right: 10px;
  width: 105px;
}

.company-Logo {
  width: 160px;
  height: 50px;
  margin-top: -6px;
  object-fit: contain; /* Ou object-fit: cover; */
}


.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 9999;
}

.loading-bar {
  width: 100%;
}



