

.divider{
    height: 20px;
    background: #898989;
}

.report-page{
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    height: 297mm;
    width: 210mm;
    position: relative;
    background: #ffff !important;
}

#totalNewsText{
font-size: 50px;
width: 100px;
}

#totalNewsNumber{
 font-size: 150px;
}


.report-page-header{
    width: 100%;
    text-align: left;
}

.report-page-header>b{
    color: #f26522; 
    font-size: 34px;  
}
.report-page-header>p{
    color:#7b7763;
    font-size: 20px;
}

.report-page-body{
    width: 100%;
    height: 252mm;
    display: flex;
    
}

.report-body-col-1{
    width: 80%;
}

.report-body-col-2{
    width: 20%;
    gap: 50px;
    display: flex;
    flex-direction: column;
}

.report-body-col-row-1{
    height: 66%;
    /* background: #fffbea; */
    white-space: nowrap;     
    overflow: hidden;          
    text-overflow: ellipsis;
    padding: 10px;
    text-transform: uppercase;
    margin-left: 15px;
    margin-top: 35px;
}

.report-body-col-row-14{
    height: 66%;
    /* background: #fffbea; */
    white-space: nowrap;     
    overflow: hidden;          
    text-overflow: ellipsis;
    padding: 10px;
    text-transform: uppercase;
    margin-left: 15px;
    margin-top: 90px;
}

.report-body-col-row-2{
    position: relative;
    height: 25%;
    /* background: #f26522; */
}

.report-legend{
    font-size: 12px;
    height: 14px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
    white-space: nowrap;     
    overflow: hidden;          
    text-overflow: ellipsis;
}

.legend-square{
    width: 10px;
    height: 10px;
    min-height: 10px;
    min-width: 10px;
}

.report-legend-label{
    white-space: nowrap;     
    overflow: hidden;          
    text-overflow: ellipsis;
}

.canvasjs-chart-canvas{
    background: transparent !important;
    background-color: transparent !important;
    top: 100px;
    cursor: not-allowed;
    pointer-events: none;

}
.canvasjs-chart-container{
    height: 100%;
}
.report-page-block{
    height: 273mm;
    padding: 60px 30px 30px 80px;
    background: #ffff;
}


.page-number{
    width: 30px;
    height: 30px;
    background: #f9c1a6;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 30px;
    right: 10px;
}

.icons-media{
    height: 50px;

}

.icons-media-space{
    height: 50px;
    position: absolute;
    bottom: 50px;
}

.user-input-container {
    margin-top: 20px;
    padding: 10px;
    width: 60%;
    /* background-color: #f9f9f9; */
    border: none;
    border-radius: 4px;
    position: absolute;
    bottom: 50px;
}

.input-field {
    width: 100%;
    padding: 10px;
    font-size: 13px;
    border: none;
    border-radius: 4px;
    resize: none;
    margin-top: 10px;
    background-color: transparent;
}

.comment-button-container {
    display: flex;
    justify-content: end;
    align-items: center;
}

.comment-button {
    width: 80px;
    height: 25px;
    border-radius: 5px;
    border: 1px solid white;
    background-color: rgb(177, 177, 177);
    color: white;
    margin-right: -22px;
    margin-top: 5px;
    cursor: pointer;
}

.formatted-comment p {
    text-align: justify;
    width: 100%;
    word-wrap: break-word;
    overflow: hidden;
    line-height: 1.6;
    padding: 10px;
    box-sizing: border-box;
    font-family: 'Arimo', sans-serif;
    font-size: 13px; 
    line-height: 1.5;
    color: #7b7763;
    margin: 0; 
}

.chart-selector-report-page {
    display: flex;
    align-items: center;
    position: absolute;
    gap: 10px;
    font-family: Arial, sans-serif;
    margin: 10px 0;
    z-index: 100;
}

.chart-selector-report-page label {
    font-size: 12px;
    font-weight: 500;
    color: #7b7763;
}

.chart-selector-report-page select {
    padding: 8px 12px;
    font-size: 14px;
    border: 2px solid #f26522;
    border-radius: 5px;
    background-color: #f9f9f9;
    color: #f26522;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    outline: none;
    cursor: pointer;
}

.chart-selector-report-page select:hover {
    background-color: #f26522;
    color: #fff;
}

.chart-selector-report-page select option {
    padding: 10px;
    font-size: 14px;
}

.custom-dropdown-report-page {
    position: relative;
    cursor: pointer;
    background-color: white;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 80px;
    height: 15px;
    margin-bottom: 0px;
    font-size: 14px;
    border: 2px solid #f26522;
    color: #f26522;
}

.dropdown-options-report-page {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: none;
    z-index: 10;
}

.dropdown-options-report-page.show {
    display: block;
}

.dropdown-options-report-page div {
    padding: 8px;
    cursor: pointer;
}

.dropdown-options-report-page div:hover {
    background-color: #f26522;
    color: white;
}

.custom-dropdown-report-page::after {
    content: '▼';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}



