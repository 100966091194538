.container_avaliacao-filter-avaliacao {
  background-color: #fff;
  display: flex;
  max-width: 720px; /* Aumentado para acomodar duas colunas */
  flex-direction: column;
  font-weight: 400;
  padding: 47px 50px;
  align-self: flex-end; /* Alinha o contêiner à direita */
  margin-left: auto; /* Move o contêiner para a direita */
}

.grid_avaliacao-filter-avaliacao {
  column-count: 2; /* Altere este valor para o número desejado de colunas */
  column-gap: 20px; /* Ajuste o espaço entre as colunas conforme necessário */
}

.date-picker-filters-avaliacao-filter-avaliacao {
  break-inside: avoid;
  margin-bottom: 10px;
}

.date-picker-label-filters-avaliacao-filter-avaliacao {
  background-color: white;
  top: -10px;
  left: 10px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.6);
  padding: 5px 3px 4px;
  font-size: 10px;
  font-family: Roboto, sans-serif;
  z-index: 1;
}

.custom-select-avaliacao-filter-avaliacao,
.custom-select-tema-filter-avaliacao {
  width: 95%;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 12px;
  position: relative;
  margin-bottom: 18px;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.custom-select-tema-filter-avaliacao[type="number"]::-webkit-outer-spin-button,
.custom-select-tema-filter-avaliacao[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-select-tema-filter-avaliacao[type="number"] {
  -moz-appearance: textfield; /* Para navegadores Firefox */
}

.feature_avaliacao-filter-avaliacao {
  display: flex;
  align-items: center;
}

.feature-label_avaliacao-filter-avaliacao {
  margin-left: 8px;
}

.block-rate_avaliacao-filter-avaliacao {
  font-family: Roboto, sans-serif;
  justify-content: center;
  align-items: center;
  border-radius: var(--borderRadius, 4px);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  background-color: var(--Foundation-Blue-blue-900, #121926);
  color: var(--primary-contrast, #fff);
  text-transform: uppercase;
  padding: 8px 22px;
  font: 500 15px/26px Roboto, sans-serif;
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: 20px;
  width: 100%;
  cursor: pointer;
}

.cancel-button_avaliacao-filter-avaliacao {
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  font: 500 15px/26px Roboto, sans-serif;
  font-size: 12px;
  width: 100%;
  cursor: pointer;
}

.cancel-button_avaliacao-filter-avaliacao:hover {
  font-family: Roboto, sans-serif;
  justify-content: center;
  align-items: center;
  border-radius: var(--borderRadius, 4px);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  background-color: var(--Foundation-Blue-blue-900, #121926);
  color: var(--primary-contrast, #fff);
  text-transform: uppercase;
  padding: 8px 22px;
  font: 500 15px/26px Roboto, sans-serif;
  font-size: 12px;
}

@media (max-width: 991px) {
  .container_avaliacao-filter-avaliacao {
    padding: 0 20px;
  }

  .grid_avaliacao-filter-avaliacao {
    grid-template-columns: 1fr; /* Uma coluna em telas menores */
  }
}

.close-button_avaliacao-filter-avaliacao {
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: var(--Foundation-Blue-blue-900, #121926);
  align-self: end;
  width: 56px;
  color: #fff;
  height: 56px;
  padding: 0 4px;
  font: 600 24px/135% IBM Plex Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
  .close-button_avaliacao-filter-avaliacao {
    padding: 0 20px;
  }
}

.title_avaliacao-filter-avaliacao {
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font: 600 24px/135% IBM Plex Sans, -apple-system, Roboto, Helvetica, sans-serif;
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .title_avaliacao-filter-avaliacao {
    max-width: 100%;
  }
}

.flex-row_avaliacao-filter-avaliacao {
  display: flex;
  margin-top: 48px;
  gap: 16px;
  letter-spacing: 0.15px;
}

@media (max-width: 991px) {
  .flex-row_avaliacao-filter-avaliacao {
    flex-wrap: wrap;
    margin-top: 40px;
  }
}

.card_avaliacao-filter-avaliacao {
  border-radius: var(--borderRadius, 4px);
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
}

@media (max-width: 991px) {
  .card_avaliacao-filter-avaliacao {
    white-space: initial;
  }
}

.card-upper_avaliacao-filter-avaliacao {
  display: flex;
  padding-bottom: 16px;
}

.card-upper-column_avaliacao-filter-avaliacao {
  display: flex;
  flex-direction: column;
}

.card-label_avaliacao-filter-avaliacao {
  background-color: var(--background-paper-elevation-0, #fff);
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
  justify-content: center;
  padding: 0 4px;
  font: 12px/100% Roboto, sans-serif;
}

.card-name_avaliacao-filter-avaliacao {
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  margin-top: 14px;
  font: 16px/150% Roboto, sans-serif;
}

.img-small_avaliacao-filter-avaliacao {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 24px;
  align-self: start;
  margin-top: 16px;
}

.value-card_avaliacao-filter-avaliacao {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.value-card-inner_avaliacao-filter-avaliacao {
  border-radius: var(--borderRadius, 4px);
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
  padding: 0 12px 16px;
}

.value-label_avaliacao-filter-avaliacao {
  background-color: var(--background-paper-elevation-0, #fff);
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
  white-space: nowrap;
  justify-content: center;
  padding: 0 4px;
  font: 12px/100% Roboto, sans-serif;
}

.value-amount_avaliacao-filter-avaliacao {
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  margin-top: 14px;
  font: 16px/150% Roboto, sans-serif;
}

.summary_avaliacao-filter-avaliacao {
  border-radius: var(--borderRadius, 4px);
  display: flex;
  margin-top: 32px;
  flex-direction: column;
}

@media (max-width: 991px) {
  .summary_avaliacao-filter-avaliacao {
    max-width: 100%;
  }
}

.summary-label_avaliacao-filter-avaliacao {
  background-color: var(--background-paper-elevation-0, #fff);
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
  justify-content: center;
  padding: 0 4px;
  font: 12px/100% Roboto, sans-serif;
}

.summary-text_avaliacao-filter-avaliacao {
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  margin-top: 14px;
  font: 16px/150% Roboto, sans-serif;
}

.summary-indicator_avaliacao-filter-avaliacao {
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
  align-self: start;
  margin-top: 32px;
  font: 16px/150% IBM Plex Sans, sans-serif;
}

.feature-item_avaliacao-filter-avaliacao {
  align-self: start;
  display: flex;
  gap: 9px;
  font-size: 16px;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  padding-bottom: 15px;
}

.radio-item_avaliacao-filter-avaliacao {
  /* Esconder o radio button padrão */
  opacity: 0;
  position: absolute;
}

.feature_avaliacao-filter-avaliacao {
  position: relative;
  display: flex;
  align-items: center; /* Alinha os itens à direita */
  margin-bottom: 10px;
}

/* Estilizar o contêiner da opção quando selecionada */
.radio-item_avaliacao-filter-avaliacao:checked + .feature-label_avaliacao-filter-avaliacao::before {
  content: '\2714'; /* Unicode para o ícone de check */
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
}

/* Estilizar o label da opção */
.feature-label_avaliacao-filter-avaliacao {
  display: inline-block;
  cursor: pointer;
}

/* Estilizar o contêiner do checkbox quadrado */
.feature-label_avaliacao-filter-avaliacao::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  margin-right: 5px;
  vertical-align: middle; /* Alinha verticalmente com o texto */
}

/* Estilizar o contêiner do checkbox quadrado quando selecionado */
.radio-item_avaliacao-filter-avaliacao:checked + .feature-label_avaliacao-filter-avaliacao::before {
  background-color: #000;
  color: #fff;
}

.custom-select-valor-filter-avaliacao {
  width: 150px;
  height: 35px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 12px;
  position: relative;
  margin-bottom: 18px;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.hr-filter-avaliacao {
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.input-wrapper {
  position: relative;
}


.currency-prefix {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 3;
  margin-top: -11px;
}

.custom-select-tema-filter-avaliacao {
  padding-left: 10px;
}