/* ClippingList.css */
.clipping-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; /* Ajuste o espaçamento entre os cards conforme necessário */
    justify-content: flex-start; /* Alinhamento dos cards */
  }
  
  .clipping-list .card {
    box-sizing: border-box;
  }
  

  .clipping-list a {
    text-decoration: none;
  }
  
  