.container-dados-usuario {
    position: relative;
  }
  
  .navbar-dados-usuario {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  }
  
  .menu-dados-usuario {
  position: fixed;
  top: 0;
  left: 0;
  /* right: 0; */
  z-index: 3;
  }
  
  .container-dados-usuario.menu-expanded .main-dados-usuario {
  margin-top: 115px;
  margin-right: 45px;
  margin-left: 220px;
  margin-bottom: 40px;
  }

  .container-dados-usuario.menu-collapsed .main-dados-usuario {
    margin-top: 115px;
    margin-right: 45px;
    margin-left: 115px;
    margin-bottom: 40px;
    }

  .tabs-container-usuario {
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    display: flex;
    align-items: center;
    gap: 0;
    letter-spacing: 0.15px;
    padding: 8px;
    width: 400px;
    margin-bottom: 40px;
  }
  
  .tab-usuario {
    flex: 1;
    text-align: center;
    cursor: pointer;
    padding: 10px 24px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    width: 200px;
  }

  .tab2-usuario {
    flex: 1;
    text-align: center;
    cursor: pointer;
    padding: 10px 24px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    width: 200px;
  }
  
  .tab-usuario {
    border-radius: 8px 0 0 8px;
    background-color: #fff;
    color: var(--text-primary, rgba(0, 0, 0, 0.87));
  }
  
  .tab2-usuario {
    border-radius: 0 8px 8px 0;
    background-color: #fff;
    color: var(--text-primary, rgba(0, 0, 0, 0.87));
  }
  
  .tab-usuario.active {
    background-color: var(--Foundation-Blue-blue-700, #0f1621);
    color: #fff;
  }
  
  .tab2-usuario.active {
    background-color: var(--Foundation-Blue-blue-700, #0f1621);
    color: #fff;
  }

 
  
  

  