*{
    print-color-adjust: exact; 
}
.report-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-family: "PT Sans", sans-serif;
    background: #898989;
}

.report-box{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divider{
    height: 20px;
    background: #898989;
}


.report-page{
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    height: 297mm;
    width: 210mm;
    position: relative;
    background: #ffff !important;
    background-color: #ffff;
}

.report-page-block-initial{
    height: 100%;
    background: #ffff;
}

.initial-page-header{
    height: 52px;
    background: #003f8a;
    width: 100%;
    display: none;
}

.initial-page-footer{
    position: absolute;
    height: 52px;
    background: #006499;
    width: 100%;
    bottom: 0;
}

.initial-page-header-line, .initial-page-footer-line{
    height: 20px;
    width: 100%;
}
.initial-page-header-line{
    background: #006499;
}
.initial-page-footer-line{
    background: #003f8a;
    position: absolute;
    bottom: 0;
    margin-bottom: 50px;
}


.initial-page-text{
    text-align: center;
    font-family: monospace;
    /* background: #ffff; */
    position: absolute;
    width: 100%;
    top: 200px;

}
.initial-page-data{
    color:#8c8b8d;
    /* background: #ffff; */

}
.initial-page-h{
    color: #4f4a4f;
    /* background: #ffff; */

}

.summary-header{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 25%;
    /* background: #ffc20e; */
    color: white;
    position: absolute;
}

#totalNewsText{
    color: white;
    font-size: 50px;
    width: 100px;
    margin-right: 100px;
}
#totalNewsNumber{
    color: white;
    font-size: 150px;
    margin-left: 100px;

}


.summary-body{
    width: 100%;
    height: 35%;
    display: flex;
}
.summary-body-column1{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction:column;
    color:white;
}

.summary-col-1-row-1{
    /* background: #0b637d; */
    width: 50%;
    height: 20%;
    display: flex;
    position: absolute;
    top: 300px;

}
.summary-col-1-row-1>div{
    padding: 0px 40px 0px 40px;
    display: inline-flex;
    align-items: center;
    width: 200px;
}
.summary-col-1-row-2{
    /* background: #005973; */
    width: 50%;
    height: 20%;
    padding: 0px 40px 0px 40px;
    display: inline-flex;
    align-items: center;
    position: absolute;
    top: 600px;
}

.summary-col-1-row-2>div{
    padding: 0px 40px 0px 0px;
    display: inline-flex;
    align-items: center;
    width: 200px;
}

.summary-body-column2{
    width: 30%;
    height: 50%;
    /* background: #daceab; */
    color: #005973;
    padding: 0px 0px 0px 15px;
    position: absolute;
    right: 0;
    top: 300px;
}

.summary-footer{
    width: 100%;
    height: 40%;
    background: #ffc20e;
    display: none;
}


.canvasjs-chart-canvas{
    background: transparent !important;
    background-color: transparent !important;
    top: 100px;
}

.canvasjs-chart-container{
    height: 100%;
}


.media-distribution{
    display: flex;
    flex-direction: column;
    margin: 150px 0px 0px 100px;
    gap: 30px;
}

.media-distribution>div{
    display: inline-flex;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.space-media{
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
}

.icons-media{
    height: 50px;

}

.icons-media-space{
    height: 50px;
    position: absolute;
    bottom: 50px;
}

.space-media-container{
    display: grid;
    justify-items: start;
    align-items: center;
    justify-content: center;
    align-content: center;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 50px;
    width: 500px;
}


.report-page-body{
    width: 100%;
    height: 252mm;
    display: flex;
}

.report-body-col-1{
    width: 80%;
}

.report-body-col-2{
    width: 20%;
    gap: 50px;
    display: flex;
    flex-direction: column;
}

.report-body-col-row-1{
    height: 66%;
    /* background: #fffbea; */
    white-space: nowrap;     
    overflow: hidden;          
   text-overflow: ellipsis; 
   padding: 10px; 
   text-transform: uppercase;
}

.report-body-col-row-2{
    position: relative;
    height: 25%;
    /* background: #f26522; */
}

.report-legend{
    font-size: 12px;
    height: 13px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
    white-space: nowrap;     
    overflow: hidden;          
    text-overflow: ellipsis;
   
}

.legend-square{
    width: 10px;
    height: 10px;
    min-height: 10px;
    min-width: 10px;
}

.report-legend-label{
    white-space: nowrap;     
    overflow: hidden;          
    text-overflow: ellipsis;
}

.page-number{
    width: 30px;
    height: 30px;
    background: #f9c1a6;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 30px;
    right: 10px;
}

.media-type-label{
    margin-left: 10px;
    font-size: 24px;
}

.chart-type{
    color: #005973;
}

.total-media-values{
    position: absolute;
    bottom: 280px;
    font-size: 24px;
    font-weight: bold;
    font-family: "PT Sans", sans-serif;
    color: #5d5c5c;
}

.total-media-values>p>b{
    color: #898989;
    font-size: 20px;
}

.report-table {
    width: 80%;
    max-height: 100%;
    border-collapse: collapse;
    height: 30px;
    margin-top: 30px;
}

.report-table th {
    border: 1px solid #ddd;
    text-align: left;
    padding: 0;
    background-color: #f2f2f2;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    color: #808080;
}

.report-table td {
    padding: 5px;
    overflow: hidden; 
    border: 1px solid #ddd;
    font-size: 10px;
    text-align: center;
}

.table-header-veiculos {
    width: 230px;
}

.table-header-dados {
    width: 60px;
}

.container-number {
    position: absolute;
    width: 135px;
    height: 22%;
    /* background: #f26522; */
    right: 30px; 
    bottom: 20px;
}

.user-input-container-analitico {
    margin-top: 20px;
    padding: 10px;
    width: 60%;
    height: 7%;
    /* background-color: #f9f9f9; */
    border: none;
    border-radius: 4px;
    position: absolute;
    bottom: 200px;
}

.input-field-analitico {
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 13px;
    border: none;
    border-radius: 4px;
    resize: none;
    margin-top: 10px;
    background-color: transparent;
}

.comment-button-container-analitico {
    display: flex;
    justify-content: end;
    align-items: center;
}

.comment-button-analitico {
    width: 80px;
    height: 25px;
    border-radius: 5px;
    border: 1px solid white;
    background-color: rgb(177, 177, 177);
    color: white;
    margin-right: -22px;
    margin-top: 5px;
    cursor: pointer;
}

.formatted-comment p {
    text-align: justify;
    width: 100%;
    word-wrap: break-word;
    overflow: hidden;
    line-height: 1.6;
    padding: 10px;
    box-sizing: border-box;
    font-family: 'Arimo', sans-serif;
    font-size: 14px; 
    line-height: 1.5;
    color: #7b7763;
    margin: 0; 
}

.report-table-container {
    width: 95%;
}

.cover-controls {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    position: absolute;
    top: 400px;
    left: 320px;
}

.cover-controls button {
    color: #f26522;
    border: 2px solid #f26522;
    border-radius: 5px;
    width: 100px;
    height: 30px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Arimo', sans-serif;
    font-size: 12px;
    cursor: pointer;
    background-color: white;
}

.cover-controls button:hover {
    color: white;
    border: none;
    background-color: #f26522;
}

.initial-page-input {
    font-size: 1.5em;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    border: none;
    border-bottom: 2px solid #ccc;
    background: transparent;
}

.initial-page-input:focus {
    outline: none;
    border-bottom: 2px solid #f26522;
}

.custom-checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    gap: 8px;
    margin-top: 5px;
    color: #f26522;
    text-transform: uppercase;
}

.custom-checkbox-input {
    display: none;
}

.custom-checkbox-box {
    width: 20px;
    height: 20px;
    border: 2px solid #f26522;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    background-color: transparent;
    position: relative;
}

.custom-checkbox-input:checked + .custom-checkbox-box {
    background-color: #f26522;
    border-color: #f26522;
}

.custom-checkbox-box::after {
    content: '';
    width: 10px;
    height: 5px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    display: none;
}

.custom-checkbox-input:checked + .custom-checkbox-box::after {
    display: block;
    width: 5px;
    height: 12px;
    margin-bottom: 2px;
}

.char-limit-info {
    font-size: 12px;
    color: #555;
    text-align: left;
    margin-top: 5px;
}
