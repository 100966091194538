.modal-overlay-save-selection {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-save-selection {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 25%;
  height: 25%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.title-save-selection {
    margin: 0;
    font-size: 1rem;
    text-transform: uppercase;
    color: #121926;
    display: flex;
    margin-bottom: 50px;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.input-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.cancel-button, .save-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button {
  background: #f44336;
  color: #fff;
}

.save-button {
  background: #4caf50;
  color: #fff;
}

.cancel-button:hover {
  background: #d32f2f;
}

.save-button:hover {
  background: #388e3c;
}

.container-save-selection {
    border-radius: var(--borderRadius, 4px);
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .filter-save-selection {
    position: relative;
  }

  .filter-save-selection-text {
    background-color: white;
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6);
    padding: 5px 3px 4px;
    font-size: 10px;
    font-family: Roboto, sans-serif;
    z-index: 1;
  }

  .input-save-selection {
    width: 95%;
    height: 40px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 12px;
    position: relative;
    margin-bottom: 18px;
    color: var(--text-primary, rgba(0, 0, 0, 0.87));
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .save-button-save-selection {
    color: white;
    background-color: #121926;
    font-feature-settings: 'clig' off, 'liga' off;
    border-radius: 4px;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    cursor: pointer;
    padding: 2px 15px;
    margin-left: 20px;
    border: none;
    margin-left: 10px;
    width: 100px;
  }

  .save-button-save-selection:hover {
    background-color: white;
    color: #121926;
    border: 1px solid #121926;
    font-weight: 600;
  }

