/* Estilos existentes */
.div-configUser {
  border-radius: 0px 0px 12px 12px;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.24);
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  display: flex;
  width: 220px;
  padding-top: 4px;
  flex-direction: column;
  font-weight: 400;
  position: absolute;
  top: 53px; /* Ajuste conforme necessário */
  right: 35px; /* Ajuste conforme necessário */
  z-index: 1000;
}

.div-2-configUser {
  align-self: start;
  display: flex;
  flex-direction: column;
  line-height: 100%;
  padding: 0 18px;
  margin-top: 10px;
}

.div-3-configUser {
  color: #000;
  font: 16px Roboto, sans-serif;
}

.div-4-configUser {
  color: var(--components-backdrop-fill, rgba(0, 0, 0, 0.5));
  margin-top: 4px;
  font: 14px Roboto, sans-serif;
}

.div-5-configUser {
  border-color: rgba(0, 0, 0, 0.24);
  border-style: solid;
  border-width: 1px;
  background-color: rgba(0, 0, 0, 0.24);
  align-self: end;
  margin-top: 15px;
  height: 1px;
}

.div-6-configUser, .div-8-configUser, .div-11-configUser {
  display: flex;
  gap: 8px;
  font-size: 14px;
  color: #04071e;
  padding: 12px 16px;
  width: 100%; /* Faz o botão ocupar 100% da largura */
  box-sizing: border-box; /* Inclui o padding na largura total */
  cursor: pointer; /* Adiciona o cursor de pointer */
}

.img-configUser {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
}

.div-7-configUser, .div-9-configUser, .div-12-configUser {
  font-family: Roboto, sans-serif;
  flex: 1;
  margin: auto 0;
  color: #04071e;
  text-decoration: none;
  width: 100%;
}

.div-10-configUser {
  border-color: rgba(0, 0, 0, 0.24);
  border-style: solid;
  border-width: 1px;
  background-color: rgba(0, 0, 0, 0.24);
  align-self: center;
  margin-top: 11px;
  width: 100%;
  height: 1px;
}

.div-11-configUser {
  margin-top: 5px;
}

.hr-horizontal-configUser {
  margin-top: 20px;
  margin-bottom: 0px;
  width: 90%;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

/* Estilos de hover para os botões */
.div-6-configUser:hover, .div-8-configUser:hover, .div-11-configUser:hover {
  background-color: rgba(0, 0, 0, 0.12); /* Cor de fundo ao passar o mouse */
}