.confirm-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.confirm-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    min-width: 30%;
    min-height: 20%;
    text-align: center;

    h2 {
        color: #121926;
        text-transform: uppercase;
        font-size: 20px;
    }

    p {
        color: #121926;
        font-size: 14px;
    }
}

.confirm-modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
}

.confirm-button {
    background-color: #121926;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 11px;
    transition: all 0.5s ease;
}

.confirm-button:hover {
    background-color: white;
    color: #121926;
    border: 1px solid #121926;
}

.cancel-button {
    background-color: white;
    color: #121926;
    border: 1px solid #121926;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 11px;
    transition: all 0.5s ease;
}

.cancel-button:hover {
    background-color: #121926;
    color: white;
    border: none;
}
