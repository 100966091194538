.App {
  position: relative;
}

.navbar {
position: fixed;
top: 0;
left: 0;
right: 0;
z-index: 2;
}

.menu {
position: fixed;
top: 0;
left: 0;
/* right: 0; */
z-index: 3;
}

.App.menu-collapsed .main {
margin-top: 115px;
margin-right: 45px;
margin-left: 115px;
margin-bottom: 40px;
}

.App.menu-expanded .main {
  margin-top: 115px;
  margin-right: 45px;
  margin-left: 220px;
  margin-bottom: 40px;
  }

.card {
align-self: stretch;
border-radius: var(--borderRadius, 4px);
border: 1px solid rgba(0, 0, 0, 0.23);
display: flex;
max-width: 164px;
flex-direction: column;
font-weight: 400;
white-space: nowrap;
letter-spacing: 0.15px;
justify-content: center;
padding: 0 12px;
}

.card-header {
align-items: start;
display: flex;
padding-bottom: 5px;
gap: 0;
}

.card-content {
display: flex;
flex-direction: column;
}

.card-label {
font-feature-settings: "clig" off, "liga" off;
background-color: var(--background-paper-elevation-0, #fff);
color: var(--text-secondary, rgba(0, 0, 0, 0.6));
justify-content: center;
padding: 0 4px;
font: 12px/100% Roboto, sans-serif;
}

.card-date {
color: var(--text-primary, rgba(0, 0, 0, 0.87));
font-feature-settings: "clig" off, "liga" off;
margin-top: 14px;
font: 16px/150% Roboto, sans-serif;
}

.card-icon {
aspect-ratio: 1;
object-fit: auto;
object-position: center;
width: 24px;
margin-top: 16px;
}

.datefilter {
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 20px;
}

.date {
margin-right: auto;
}

.filters {
margin-left: auto;
}

.card-list {
display: flex;
flex-wrap: wrap;
justify-content: first baseline;
align-items: center;
gap: 20px;
}

.category-cards {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
}

.category-card {
width: 375px;
margin-bottom: 20px;
padding: 0px 20px 0px 20px;
border: 1px solid #ccc;
box-sizing: border-box;
border-radius: 15px;
}

.category-card h3 {
margin-bottom: 15px;
color: var(--Foundation-Blue-blue-800, #202939);
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 24px */
}

.category-button {
cursor: pointer;
margin-top: 10px;
}

.category-content {
display: flex;
align-items: center;
}

.category-image {
width: 50px;
height: 50px;
margin-right: 10px;
}

.category-details {
display: flex;
flex-direction: column;
}

.category-name {
font-weight: bold;
}

.category-count {
font-size: 12px;
color: #666;
}

.hr {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.pagination-wrapper-tela-noticias {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 7px;
  margin-top: 20px;
}

.welcome-message {
  font-size: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #121926;
  background: rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  height: 100%;
  padding: 0px 20px;
}

.user-info {
  margin-top: -25px;
  font-size: 13px;
  font-weight: bold;
  margin-top: 0px;
}

.advanced-search-button-open {
  width: 150px;
  height: 40px;
  color: white;
  background-color: #121926;
  border-radius: 4px;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 173.333% */
  letter-spacing: 0.46px;
  text-transform: uppercase;
  cursor: pointer;
}

.advanced-search-button-close {
  width: 150px;
  height: 40px;
  color: white;
  background-color: rgba(218, 3, 3, 0.856);
  border: 1px solid rgba(218, 3, 3, 0.856);
  border-radius: 4px;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 173.333% */
  letter-spacing: 0.46px;
  text-transform: uppercase;
  cursor: pointer;
}