.search-container-filters {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -20px;
  padding-left: 80px;
}

.date-picker-filters {
  margin-right: 40px;
  position: relative;
}

.date-picker-label-filters {
  background-color: white;
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.6);
  padding: 5px 3px 4px;
  font-size: 10px;
  font-family: Roboto, sans-serif;
  z-index: 1;
}

.custom-select {
  width: 240px;
  height: 42px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 12px;
  position: relative;
  margin-bottom: 18px;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.custom-dropdown {
  position: relative;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
  width: 200px;
  height: 25px;
  margin-bottom: 18px;
}

.custom-dropdown::after {
  content: '▼';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu li {
  padding: 8px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #ccc; /* Cor cinza */
}
