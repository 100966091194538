.container-pesquisa {
    position: relative;
  }
  
  .navbar-pesquisa {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  }
  
  .menu-pesquisa {
  position: fixed;
  top: 0;
  left: 0;
  /* right: 0; */
  z-index: 3;
  }

  .container-pesquisa.menu-expanded .main-pesquisa {
    margin-top: 50px;
    margin-right: 45px;
    margin-left: 220px;
    margin-bottom: 40px;
    }
  
  .container-pesquisa.menu-collapsed .main-pesquisa {
  margin-top: 50px;
  margin-right: 45px;
  margin-left: 115px;
  margin-bottom: 40px;
  }