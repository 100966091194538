.category-button {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 32px;
  transition: background-color 0.3s ease;
  width: 34px;
  height: 75px;
}

.category-button:hover {
  background-color: #f0f0f0;
}

.category-content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.category-image {
  width: 40px;
  height: auto;
  margin-bottom: 5px;
  border-radius: 50%;
  margin-left: 6px;
}

.category-details {
  margin-bottom: 5px;
  text-align: center;
}

.category-name {
  font-weight: 500;
  font-size: 9.5px;
  margin-right: 2px;
}

.category-count {
  color: #888;
  font-size: 11px;
}

