.filter-news-container {
  font-size: 14px;
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
  font-weight: 400;
  line-height: 160%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 40px;
}

.filter-news-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 200px;
  max-width: 716px;
  margin-bottom: 12px;
}

.filter-news-input {
  flex: 1;
  width: 50%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  padding: 12px;
}

.filter-news-button {
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: white;
  padding-left: 0;
}

.filter-news-icon {
  width: 65%;
  height: 100%;
  max-height: 100%;
  object-fit: contain;
  margin-right: 8px;
  min-width: 42px;
}

.selected-categories {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.selected-category {
  padding: 2px 12px 2px 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  border-radius: 30px;
  box-sizing: border-box;
  background: var(--action-selected, rgba(0, 0, 0, 0.08));
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
}

.close-icon {
  width: 16px;
  height: 16px;
  margin-left: 12px;
  cursor: pointer;
  margin-bottom: -2px;
}

.divider-line {
  border: none;
  height: 1px;
  background-color: #ccc;
  width: 100%;
  margin: 10px 0;
}
