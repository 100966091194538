.modal-overlay-saved-selections {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content-saved-selections {
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    width: 90%;
    max-width: 1000px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    animation: fadeIn 0.3s ease-in-out;
  }

  .modal-header-saved-selections {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  .title-saved-selections {
    margin: 0;
    font-size: 1rem;
    text-transform: uppercase;
    color: #121926;
    display: flex;
  }
  
  .close-button-saved-selections {
    background: none;
    border: none;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    top: 15px;
    right: 15px;
    color: #dbdada;
    transition: color 0.2s ease-in-out;
  }
  
  .close-button-saved-selections:hover {
    color: #121926;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  th {
    background-color: #121926;
    font-weight: bold;
    color: white;
  }
  
  td {
    background-color: white;
    color: #121926;
  }
  
  tr:nth-child(even) td {
    background-color: white; 
    color: #121926;
  }


  .use-button-saved-selections {
    color: white;
    background-color: #121926;
    font-feature-settings: 'clig' off, 'liga' off;
    border-radius: 4px;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    cursor: pointer;
    padding: 2px 15px;
    margin-left: 20px;
    border: none;
    margin-left: 10px;
    width: 100px;
  }

  .use-button-saved-selections:hover {
    background-color: white;
    color: #121926;
    border: 1px solid #121926;
    font-weight: 600;
  }

  .delete-button-saved-selections {
    color: #121926;
    background-color: white;
    font-feature-settings: 'clig' off, 'liga' off;
    border-radius: 4px;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    cursor: pointer;
    padding: 2px 15px;
    margin-left: 20px;
    border: 1px solid #121926;
    margin-left: 10px;
    width: 100px;
  }

  .delete-button-saved-selections:hover {
    background-color: #121926;
    color: white;
    border: 1px solid #121926;
    font-weight: 600;
  }