.container-redefinicao-senha {
  position: relative;
}

.navbar-dados-senha {
position: fixed;
top: 0;
left: 0;
right: 0;
z-index: 2;
}

.menu-dados-senha {
position: fixed;
top: 0;
left: 0;
/* right: 0; */
z-index: 3;
}

.container-redefinicao-senha.menu-expanded .main-dados-senha {
margin-top: 80px;
margin-right: 0px;
margin-left: 220px;
margin-bottom: 40px;
}

.container-redefinicao-senha.menu-collapsed .main-dados-senha {
  margin-top: 80px;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 40px;
  }

.redefinicao-senha {
    max-width: 640px;
  }
  
  .div-2-redefinicao-senha {
    gap: 20px;
    display: flex;
    margin-top: 80px;
  }

  .column-redefinicao-senha {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 19%;
    margin-left: 0px;
  }

  .img-redefinicao-senha {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 120px;
    border-radius: 50%;
    max-width: 100%;
  }

  .column-2-redefinicao-senha {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 81%;
    margin-left: 20px;
  }

  .div-3-redefinicao-senha {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    margin: auto 0;
    padding: 0 20px;
  }

  .div-4-redefinicao-senha {
    color: var(--text-primary, rgba(0, 0, 0, 0.87));
    letter-spacing: -0.5px;
    font: 600 32px/150% IBM Plex Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }

  .div-5-redefinicao-senha {
    color: var(--text-secondary, rgba(0, 0, 0, 0.6));
    text-decoration-line: underline;
    font: 400 13px/170% IBM Plex Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }




  .input-redefinicao-senha {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: -20px;
    margin-top: 80px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    padding: 30px 30px 20px 30px;
    border-radius: 10px;
  }
  

  .date-picker-redefinicao-senha{
    margin-right: 40px;
    position: relative;
  }
  
  .date-picker-label-redefinicao-senha{
    background-color: white;
    position: absolute;
    top: -10px;
    left: 10px;
    color: rgba(0, 0, 0, 0.6);
    padding: 5px 3px 4px;
    font-size: 10px;
    font-family: Roboto, sans-serif;
    z-index: 1;
  }

  .custom-select-redefinicao-senha {
    width: 340px;
    height: 42px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 12px;
    position: relative;
    margin-bottom: 18px;
    color: var(--text-primary, rgba(0, 0, 0, 0.87));
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
}



.Buttons-redefinicao-senha {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}

.voltar-redefinicao-senha {
    width: 166px;
    height: 55px;
    display: flex;
    padding: 8px 22px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: var(--borderRadius, 4px);
    border: 1px solid var(--components-input-outlined-enabledBorder, rgba(0, 0, 0, 0.23));
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
    color: #121926;
    font-size: 14px;
    background-color: transparent;
}

.salvar-redefinicao-senha {
    width: 160px;
    height: 55px;
    display: flex;
    padding: 8px 22px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: var(--borderRadius, 4px);
    background: var(--Foundation-Blue-blue-900, #121926);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);
    color: white;
    text-transform: uppercase;
    cursor: pointer;
}

.error-message {
  margin-bottom: -15px;
}