.Busca-avancada {
  position: relative;
}

.navbar-pesquisa {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  }
  
  .menu-pesquisa {
  position: fixed;
  top: 0;
  left: 0;
  /* right: 0; */
  z-index: 3;
  }

  .Busca-avancada.menu-expanded .main-pesquisa {
    margin-top: 50px;
    margin-right: 45px;
    margin-left: 220px;
    margin-bottom: 40px;
    }
  
  .Busca-avancada.menu-collapsed .main-pesquisa {
  margin-top: 50px;
  margin-right: 45px;
  margin-left: 115px;
  margin-bottom: 40px;
  }

.date-picker-filters-pesquisar {
  margin-right: 20px;
  position: relative;
  margin-bottom: 15px;
}

.date-picker-label-pesquisar {
  background-color: white;
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.6);
  padding: 5px 3px 4px;
  font-size: 10px;
  font-family: Roboto, sans-serif;
  z-index: 1;
}

.custom-select-pesquisar  {
  width: 240px;
  height: 42px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 12px;
  position: relative;
  margin-bottom: 18px;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  height: 65px;
}

.filters {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-right: -20px;
}

.filter-container-pesquisar {
  margin-right: 20px;
  position: relative;
  margin-bottom: 15px;
}

.filter-label-pesquisar {
  background-color: white;
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.6);
  padding: 5px 3px 4px;
  font-size: 10px;
  font-family: Roboto, sans-serif;
  z-index: 1;
}

.filter-select-pesquisar {
  width: 25.55vw; /* Ajusta a largura para 50% da largura da tela */
  height: 52px;
  border-radius: 3px;
  font-size: 12px;
  position: relative;
  margin-bottom: 18px;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}




.filters2 {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-right: -20px;
}

.summary_pesquisar {
  border-radius: var(--borderRadius, 4px);
  display: flex;
  flex-direction: column;
}

.date-picker-filters-pesquisar-text {
  margin-right: 20px;
  position: relative;
}

.date-picker-label-filters-pesquisar {
  background-color: white;
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.6);
  padding: 5px 3px 4px;
  font-size: 10px;
  font-family: Roboto, sans-serif;
  z-index: 1;
}

.custom-select-pesquisar-text {
  width: 380px;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 12px;
  position: relative;
  margin-bottom: 18px;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.filter-container-pesquisar2 {
  margin-right: 20px;
  position: relative;
  margin-bottom: 15px;
}

.filter-label-pesquisar2 {
  background-color: white;
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.6);
  padding: 5px 3px 4px;
  font-size: 10px;
  font-family: Roboto, sans-serif;
  z-index: 1;
}

.filter-select-pesquisar2 {
  width: 16.55vw; /* Ajusta a largura para 50% da largura da tela */
  height: 52px;
  border-radius: 3px;
  font-size: 12px;
  position: relative;
  margin-bottom: 18px;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}



.filters3 {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-right: -20px;
  max-width: 344px;
  gap: 16px;
  white-space: nowrap;
  margin-top: 10px;
  max-width: 420px;
}

.date-picker-search {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
  font-weight: 400;
  letter-spacing: 0.15px;
  justify-content: center;
  padding: 0 8px;
  height: 50px;
  width: 2000px;
}

.date-picker-header-search {
  align-items: start;
  display: flex;
  padding-bottom: 16px;
}

.date-picker-content-search {
  display: flex;
  flex-direction: column;
}

.date-picker-label-search {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  padding: 0px 8px 6px 4px;
  font: 10px/100% Roboto, sans-serif;
  width: 50px;
}

.selected-date-search {
  color: rgba(0, 0, 0, 0.87);
  margin-top: 5px;
  margin-left: 4px;
  font: 12.5px/150% Roboto, sans-serif;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.calendar-icon-search {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
  margin-top: 7px;
  /* margin-left: 35px; */
  cursor: pointer;
}




.filters4 {
  display: flex;
  align-items: baseline;
  justify-content: start;
  margin-right: -20px;
  gap: 16px;
  white-space: nowrap;
  margin-top: 10px;
  width: 100%;
}

.filter-container-search {
  margin-top: 40px;
  width: 90%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px dashed rgba(33, 150, 243, 1);
  background-color: var(--primary-hover, rgba(33, 150, 243, 0.04));
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  padding: 10px;
  font: 400 16px/150% Roboto, sans-serif;
  letter-spacing: 0.15px;
}

.ButtonLimpar {
  width: 200px;
  height: 55px;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  background-color: transparent;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 4px;
  /* button/large */
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 173.333% */
  letter-spacing: 0.46px;
  text-transform: uppercase;
    }



    .buttons {
      display: flex;
      align-items: baseline;
      justify-content: end;
      margin-right: -20px;
      gap: 16px;
      white-space: nowrap;
      margin-top: 40px;
      width: 100%;
    }

    .ButtonVoltar {
      width: 200px;
      height: 55px;
      color: var(--text-primary, rgba(0, 0, 0, 0.87));
      background-color: transparent;
      font-feature-settings: 'clig' off, 'liga' off;
      border-radius: 4px;
      /* button/large */
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px; /* 173.333% */
      letter-spacing: 0.46px;
      text-transform: uppercase;
      border: 1px solid rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
    }

    .ButtonSearch {
      width: 200px;
      height: 55px;
      color: white;
      background-color: #121926;
      font-feature-settings: 'clig' off, 'liga' off;
      border-radius: 4px;
      /* button/large */
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px; /* 173.333% */
      letter-spacing: 0.46px;
      text-transform: uppercase;
      cursor: pointer;
    }

    .avaliacao-filters {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    
    .filter-tag {
      background-color: #f0f0f0;
      border: 1px solid #ccc;
      border-radius: 20px;
      padding: 5px 10px;
      display: flex;
      align-items: center;
    }
    
    .remove-filter {
      background: none;
      border: none;
      margin-left: 5px;
      cursor: pointer;
      color: white;
      background-color: rgb(202, 3, 3);
      border-radius: 30px;
      font-size: 1rem;
    }

    .hr-horizontal-busca {
      margin-top: 20px;
      margin-bottom: -20px;
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.12);
    }

    .modal-overlay-busca-avancada {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
      display: flex;
      justify-content: end;
      z-index: 1000;
    }
    
    .modal-content-busca-avancada {
      background: white;
      padding: 20px;
      border-radius: 5px;
      width: 80%;
      max-width: 500px;
    }

    .selected-filters {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 40px;
    }
    
    .selected-filter-item {
      background-color: rgba(0, 0, 0, 0.08);
      padding: 5px;
      padding-left: 10px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .remove-filter-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      border-radius: 100px;
      opacity: 0.80;
      margin-left: 10px;
      cursor: pointer;
    }

    .loading-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5); /* Cor de fundo com transparência */
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999; /* Certifique-se de que está acima de todos os outros elementos */
      visibility: hidden; /* Inicialmente invisível */
      transition: visibility 0.3s ease-in-out;
    }
    
    .loading-overlay.active {
      visibility: visible; /* Torna visível quando ativo */
    }
    
    .date-filter {
      display: flex;
      align-items: center;
      justify-content: end;
      margin-top: -90px;
      margin-bottom: 40px;
      margin-left: 800px;
  }
  
  .container-selecao {
      display: flex;
      gap: 20px;
      font-size: 15px;
      color: var(--text-primary, rgba(0, 0, 0, 0.87));
      font-weight: 500;
      letter-spacing: 0.46px;
      line-height: 26px;
      margin-bottom: 40px;
      margin-top: 40px;
      display: flex;
      align-items: center;
    }
  
    @media (max-width: 991px) {
      .container {
        flex-wrap: wrap;
      }
    }
  
    .select-options-wrapper {
      border-radius: 4px;
      border-color: rgba(0, 0, 0, 0.23);
      border-style: solid;
      border-width: 1px;
      display: flex;
      gap: 16px;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.15px;
      line-height: 150%;
      flex: 1;
      padding: 7px 24px;
      align-items: center;
    }
  
    @media (max-width: 1450px) {
      .select-options-wrapper {
        padding: 0px 8px;
        font-size: 14px;
      }
    }
  
    @media (max-width: 1550px) {
      .select-options-wrapper {
        padding: 10px 8px;
        font-size: 12px;
      }
    }
  
    .option {
      display: flex;
      gap: 9px;
      flex: 1;
      padding: 9px;
    }
  
    .img {
      aspect-ratio: 1;
      object-fit: auto;
      object-position: center;
      width: 24px;
    }
  
    .text {
      font-feature-settings: "clig" off, "liga" off;
      font-family: Roboto, sans-serif;
    }
  
    .export-button-wrapper-selecao {
      border-color: rgba(0, 0, 0, 0.5);
      border-width: 1px;
      display: flex;
      flex-direction: column;
      white-space: nowrap;
      text-transform: uppercase;
      justify-content: center;
    }
  
    @media (max-width: 991px) {
      .export-button-wrapper {
        white-space: initial;
      }
    }
  
    .export-button-selecao {
      height: 55px;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Roboto, sans-serif;
      justify-content: center;
      border-radius: var(--borderRadius, 4px);
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
      background-color: rgba(18, 25, 38, 0);
      padding: 8px 22px;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: bold;
    }
  
    @media (max-width: 991px) {
      .export-button {
        white-space: initial;
        padding: 0 20px;
      }
    }
  
    .evaluate-button-selecao {
      font-feature-settings: "clig" off, "liga" off;
      font-family: Roboto, sans-serif;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--borderRadius, 4px);
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
      background-color: var(--Foundation-Blue-blue-900, #121926);
      color: var(--primary-contrast, #fff);
      white-space: nowrap;
      text-transform: uppercase;
      padding: 18px 22px 18px 22px;
      margin-top: 2px;
      cursor: pointer;
      font-size: 11px;
      width: 150px;
    }
  
    @media (max-width: 991px) {
      .evaluate-button {
        white-space: initial;
        padding: 0 20px;
      }
    }
  
    .filtros {
      display: flex;
      margin-right: 20px;
    }
  
    .filtersearch-selecao {
      width: 80%;
    }
  
    .Resultado-Busca-cards {
      display: flex;
      flex-direction: column;
      gap: 16px; /* Espaçamento entre os cartões */
      justify-content: center;
    }
    
    .Card {
      flex: 1 1 100%; /* Ajuste a largura dos cartões conforme necessário */
      box-sizing: border-box; /* Inclui padding e border na largura total do elemento */
      margin-bottom: 16px; /* Espaçamento inferior para separar as linhas de cartões */
    }
  
    .custom-radio {
      display: none;
    }
    
    .custom-radio-label {
      position: relative;
      cursor: pointer;
      padding-left: 35px;
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
    
    .custom-radio-label .custom-checkbox {
      position: absolute;
      left: 0;
      top: 0;
      width: 5px;
      height: 5px;
      border: 2px solid #ccc;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px
    }
    
    .custom-radio:checked + .custom-radio-label .custom-checkbox {
      background-color: #121926;
      border-color: #ccc;
    }
    
    .custom-radio:checked + .custom-radio-label .custom-checkbox::before {
      content: '✔';
      font-size: 18px;
      color: white;
    }
    
    .custom-radio-label .text {
      margin-left: 10px;
    }
  
    @media (max-width: 1600px) {
      .custom-radio-label .text  {
        font-size: 10px;
      }
    }
  
    .custom-radio {
      display: none;
    }
    
    .custom-radio-label {
      position: relative;
      cursor: pointer;
      padding-left: 20px;
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
    
    .custom-radio-label .custom-checkbox {
      position: absolute;
      left: 0;
      top: 0;
      width: 17px;
      height: 17px;
      border: 2px solid #ccc;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .custom-radio:checked + .custom-radio-label .custom-checkbox {
      background-color: #a8a8a8;
      border-color: #ccc;
    }
    
    .custom-radio:checked + .custom-radio-label .custom-checkbox::before {
      content: '';
      font-size: 18px;
      color: white;
    }
    
    .custom-radio-label .text {
      margin-left: 10px;
    }
    
    .select-all-checkbox {
      display: flex;
      align-items: center;
      margin: 10px 0;
    }
    
    .card-container {
      position: relative;
    }
    
    .card-checkbox {
      position: absolute;
      top: 3px;
      right: 5px;
    }
    
    .custom-card-checkbox {
      display: none;
    }
    
    .custom-card-checkbox-label {
      width: 25px;
      height: 25px;
      border: 2px solid #ccc;
      background-color: white;
      display: inline-block;
      cursor: pointer;
      border-radius: 5px;
      margin-top: 38px;
      margin-right: 30px;
    }
    
    .custom-card-checkbox:checked + .custom-card-checkbox-label {
      background-color: #121926;
      border-color: #ccc;
    }
    
    .custom-card-checkbox:checked + .custom-card-checkbox-label::before {
      content: '✔';
      font-size: 15px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  
  
  .custom-card-size .card-container .card {
    max-width: 100%;
    min-height: 100px;
    height: auto;
    padding: 15px;
  }
  
  .custom-card-size .card-container .card-header {
    gap: 10px;
  }
  
  .custom-card-size .card-container .thumbnail-wrapper {
    justify-content: center;
  }
  
  .custom-card-size .card-container .card-title {
    font-size: 16px;
  }
  
  .custom-card-size .card-container .card-detail {
    margin-top: 8px;
    gap: 12px;
    font-size: 9px;
    font-weight: bold;
  }
  
  .modal-overlay-resultado-busca {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: end;
    z-index: 1000;
  }
  
  .modal-content-resultado-busca {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    max-width: 90%;
    height: 100%;
    overflow-y: auto;
  }
  
  .pagination-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 7px;
  }
  
  .pagination-info {
    text-align: center;
    font-size: 13px;
    padding: 15px;
    font-family: 'Roboto', sans-serif;
    background-color: #f9f9f9;
    color: #333;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @media (max-width: 1600px) {
    .pagination-info  {
      font-size: 12px;
      height: 30px;
    }
  }
  
  @media (max-width: 1450px) {
    .pagination-info  {
      font-size: 10px;
      height: 30px;
    }
  }
  
  .pagination-button {
    border: 1px solid #ccc;
    background-color: #fff;
    color: #333;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
    height: 40px;
    width: 40px;
    font-family: Roboto, sans-serif;
    margin-right: 5px;
  }
  
  .pagination-button2 {
    border: 1px solid #ccc;
    background-color: #fff;
    color: #333;
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
    height: 40px;
    width: 90px;
    font-size: 10px;
    font-weight: bold;
    font-family: Roboto, sans-serif;
  }
  
  @media (max-width: 1600px) {
    .pagination-button2  {
      width: 65px;
      font-size: 9px;
      font-weight: bold;
      margin: 0 2px;
    }
  }
  
  .pagination-button:hover {
    background-color: #eee;
  }
  
  .pagination-button2:hover {
    background-color: #121926;
    color: #eee;
  }
  
  .pagination-button.active {
    background-color: #121926;
    color: #fff;
    border: 1px solid #121926;
  }
  
  .pagination-button2:disabled {
    background-color: #f5f5f5;
    color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination-ellipsis {
    display: inline-block;
    padding: 10px 10px;
    color: #333;
  }

  .selected-items-info {
    width: 75%;
    display: flex;
    align-items: center;
  }

  .selected-items-info span {
    font-family: 'Arimo', sans-serif;
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
  }

  .clear-selection-button {
    color: #121926;
    background-color: white;
    font-feature-settings: 'clig' off, 'liga' off;
    border-radius: 4px;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    cursor: pointer;
    padding: 2px 15px;
    margin-left: 20px;
    border: 1px solid #121926;
  }
  
  .clear-selection-button:hover {
    background-color: #121926;
    color: white;
    border: 1px solid #121926;
    font-weight: 600;
  }

  #selected-items-visible {
    display: block;
  }
  
  #selected-items-hidden {
    display: none;
  }
  
  .save-selection-button {
    color: white;
    background-color: #121926;
    font-feature-settings: 'clig' off, 'liga' off;
    border-radius: 4px;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    cursor: pointer;
    padding: 2px 15px;
    margin-left: 20px;
    border: none;
    margin-left: 10px;
  }

  .save-selection-button:hover {
    background-color: white;
    color: #121926;
    border: 1px solid #121926;
    font-weight: 600;
  }
