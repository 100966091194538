/* #Geral */
.Container-cadastrar-noticia {
    position: relative;
  }
  
  .navbar-cadastrar-noticia {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
  
  .menu-cadastrar-noticia {
    position: fixed;
    top: 0;
    left: 0;
    /* right: 0; */
    z-index: 3;
  }
  
  .Container-cadastrar-noticia.menu-expanded .main-cadastrar-noticia {
    margin-top: 130px;
    margin-right: 45px;
    margin-left: 220px;
    margin-bottom: 40px;
  }

  .Container-cadastrar-noticia.menu-collapsed .main-cadastrar-noticia {
    margin-top: 130px;
    margin-right: 45px;
    margin-left: 115px;
    margin-bottom: 40px;
  }
  
  .filters-cadastrar-noticia {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .search-container-cadastrar-noticia {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: -48px;
  }
  
  .date-picker-cadastrar-noticia {
    flex: 1 1 calc(33% - 20px); /* Ajuste para ter até 3 colunas por linha */
    position: relative;
  }
  
  .date-picker-label-cadastrar-noticia {
    background-color: white;
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6);
    padding: 14px 5px 0px 0px;
    font-size: 10px;
    font-family: Roboto, sans-serif;
    z-index: 1;
  }
  
  .input-cadastrar-noticia, .textarea-cadastrar-noticia {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 20px;
  }



  /* #DADOS CADASTRAR NOTICIAS */
  
  .dados-cadastrar-noticia {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 1860px) {
    .dados-cadastrar-noticia {
      width: 100%;
    }
  }
  
  .search-text-cadastrar-noticia {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: -30px;
  }
    
  .date-text-cadastrar-noticia {
    flex: 1 1 calc(33% - 20px); /* Ajuste para ter até 3 colunas por linha */
    position: relative;
  }
    
  .date-text-label-cadastrar-noticia {
    background-color: white;
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6);
    padding: 5px 5px 0px 0px;
    font-size: 10px;
    font-family: Roboto, sans-serif;
    z-index: 1;
  }
  
  .custom-select-cadastrar-noticia {
    width: 450px;
    height: 30px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 12px;
    position: relative;
    margin-bottom: 18px;
    color: var(--text-primary, rgba(0, 0, 0, 0.87));
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  /* #INFORMAÇÔES CADASTRAR NOTICIA */
  
  .informacao-cadastrar-noticia {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 20px;
  }
  
  .dados-e-materiais {
    display: flex;
    justify-content: space-between;
  }
  
  .dados-texto {
    width: 48%; /* Ajuste conforme necessário */
    display: flex;
    flex-direction: column;
  }

  .search-informacao-cadastrar-noticia {
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }
    
  .date-informacao-cadastrar-noticia {
    flex: 1 1 calc(33% - 20px); /* Ajuste para ter até 3 colunas por linha */
    position: relative;
    margin-top: 18px;
  }
    
  .date-informacao-label-cadastrar-noticia {
    background-color: white;
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6);
    padding: 5px 5px 0px 0px;
    font-size: 10px;
    font-family: Roboto, sans-serif;
    z-index: 1;
  }

  .custom-informacao-cadastrar-noticia {
    width: 800px;
    height: 30px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 12px;
    position: relative;
    margin-bottom: 18px;
    color: var(--text-primary, rgba(0, 0, 0, 0.87));
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  .custom-informacao-cadastrar-noticia-tempo {
    width: 400px;
    height: 30px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 12px;
    position: relative;
    margin-bottom: 18px;
    color: var(--text-primary, rgba(0, 0, 0, 0.87));
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  @media (max-width: 1860px) {
    .custom-informacao-cadastrar-noticia {
      width: 610px;
    }
  }

/* Adicione os novos estilos para os botões de navegação */
.prev-button, .next-button {
  background-color: transparent;
  color: #121926;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 16px;
}

.file-preview-container {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.file-preview {
  max-width: 50%; /* Ajuste conforme necessário */
  max-height: 180px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Adicionado para garantir que a imagem não ultrapasse os limites da div */
  margin: 0 10px;
}

.file-preview img, .file-preview video, .file-preview audio {
  max-width: 100%;
  max-height: 100%;
}

.nav-buttons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.prev-button-container {
  left: 0;
}

.next-button-container {
  right: 0;
}


.search-midia-cadastrar-noticia {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}
  
.date-midia-cadastrar-noticia {
  width: 765px;
  flex: 1 1 calc(33% - 20px); /* Ajuste para ter até 3 colunas por linha */
  position: relative;
  border: 1px solid #ccc;
  border-radius: 7px;
  padding: 10px;
}

@media (max-width: 1860px) {
  .date-midia-cadastrar-noticia {
    width: 540px;
  }
}

  
.date-midia-label-cadastrar-noticia {
  background-color: white;
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.6);
  padding: 5px 5px 0px 0px;
  font-size: 10px;
  font-family: Roboto, sans-serif;
  z-index: 1;
}

.custom-midia-cadastrar-noticia {
  width: 750px;
  height: 30px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 12px;
  position: relative;
  margin-bottom: 18px;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}


/* #MATERIAIS CADASTRAR NOTICIA */
  
  .materiais-cadastrar-noticia {
    width: 46.2%; /* Ajuste conforme necessário */
    display: flex;
    flex-direction: column;
  }

  .search-materiais-cadastrar-noticia {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }
    
  .date-materiais-cadastrar-noticia {
    flex: 1 1 calc(33% - 20px); /* Ajuste para ter até 3 colunas por linha */
    position: relative;
  }
    
  .date-materiais-label-cadastrar-noticia {
    background-color: white;
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6);
    padding: 5px 5px 0px 0px;
    font-size: 10px;
    font-family: Roboto, sans-serif;
    z-index: 1;
  }

  .custom-materiais-cadastrar-noticia {
    width: 750px;
    height: 30px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 12px;
    position: relative;
    margin-bottom: 18px;
    color: var(--text-primary, rgba(0, 0, 0, 0.87));
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 60px;
  }




  /* #BUTOON */
  
  .button-cadastrar-noticia {
    position: absolute;
    right: 0;
    bottom: 30px;
    right: 40px;
  }
  
  .button-cadastrar {
    width: 160px;
    height: 55px;
    background-color: #121926;
    color: white;
    text-transform: uppercase;
    border-radius: 4px;
    cursor: pointer;
  }

  @media (max-width: 1860px) {
    .button-cadastrar-noticia {
      position: absolute;
      right: 0;
      margin-right: 45px;
    }
  }


  .textarea-resumo {
    height: 30px;
    width: 97%;
    resize: none; /* Desativa o redimensionamento */
}

.textarea-conteudo {
    height: 190px;
    width: 97%;
    resize: none; /* Desativa o redimensionamento */
}

@media (max-width: 1860px) {
  .textarea-resumo {
    height: 70px;
  }

  .textarea-conteudo {
    height: 250px;
  }
}

.date-time-container {
  display: flex;
  gap: 10px;
  margin-right: -12px;
}

.date-input, .time-input {
  width: 50%;
}
  
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 95%;
}

@media (max-width: 1860px) {
  .date-time-container {
    display: flex;
    gap: 10px;
    margin-right: -12px;
    width: 530px;
  }
}


.file-upload-container {
  display: flex;
  align-items: center;
}

.custom-file-button {
  width: 97%;
  height: 35px;
  background-color: #121926;
  color: white;
  text-transform: uppercase;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 10px;
  cursor: pointer;
}


.file-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.custom-file-button {
  flex-grow: 1;
}

.clear-file-button {
  width: 12%;
  height: 30px;
  margin-left: 10px;
  background-color: darkred;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
  align-self: flex-end;
  margin-top: 15px;
}

