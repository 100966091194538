.erro404-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background: linear-gradient(135deg, #E3E8Ef, #CDD5DF, #9AA4B2, #697586);
}

.display-1 {
  font-size: 6rem;
  margin-bottom: 20px;
  color: #121926;
  font-size: 140px;
  margin-top: -50px;
}

.custom-emoji {
  vertical-align: middle;
  margin-left: 10px;
  margin-right: 10px;
}

.error-details {
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 40px;
  margin-top: -15px;
}

.text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-secondary {
  color: #fff;
  background-color: #121926;
  border-color: #121926;
  padding: 10px 20px;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 4px;
}

.text-tela-error {
  color: #6c757d;
  font-size: 22px;
  font-weight: bold;
}


/* <------------RESPONSIVE------------> */

@media (max-width: 321px) {
  .display-1 {
    font-size: 120px;
  }
}
